import type {
  AppExposedApis,
  EditorSDK,
  EditorReadyOptions,
} from '@wix/platform-editor-sdk';

import { GetAppManifestFn } from '@wix/yoshi-flow-editor';

import { initPresetChangedEventListener } from './editor.app.services/presetChangedEventListener';
import { componentDeletedEventListener } from './editor.app.services/componentDeletedEventListener';
import { widgetGfppClickedEventListener } from './editor.app.services/widgetGfppClickedEventListener';
import { componentGfppClickedEventListener } from './editor.app.services/componentGfppClickedEventListener';
import { getAppManifestWrapper } from './editor.app.services/appManifest';
import { addWidgetWrapper } from './editor.app.services/addWidget';
import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import { bookingsAppSettingsOpened } from '@wix/bi-logger-wixboost-users/v2';
import { widgetPresetsData } from './components/all presets/editor.controller';

// This code exists for debugging purposes
// export const onEvent = (...arg: any) => {
//   console.log('Got event:', arg);
// };

export const editorReady = async (editorSDK, appDefId, options, flowAPI) => {
  console.log('editorSDK:', editorSDK);
  console.log('appDefId:', appDefId);
  console.log('flowAPI:', flowAPI);
  console.log('options:', options);
  if (
    options.firstInstall &&
    (options.origin.info?.type === 'APP_MARKET' ||
      options.origin.info?.type === 'IMPORT_PANEL' ||
      options.origin.info?.type === 'SILENT_INSTALL' ||
      options.origin?.type === 'RESPONSIVE')
  ) {
    // INSTALLTION - COMMENT FOR ADD PANEL
    console.log('Running addWidget flow');
    await addWidgetWrapper({ editorSDK });
  }

  const isStudio = getIsStudio(options);
  console.log('isStudio', isStudio);

  const customPanelOptions = {
    editorSDK,
    editorType: options.origin.type,
    essentials: flowAPI.essentials,
  };
  const panelsApi = await new PanelsApiFactory().createPanelsApi(
    customPanelOptions,
  );

  await initPresetChangedEventListener({
    editorSDK,
    widgetPresetsData,
    isStudio,
  });
  await componentDeletedEventListener({
    editorSDK,
    flowAPI,
    widgetPresetsData,
    isStudio,
  });
  await widgetGfppClickedEventListener({
    editorSDK,
    flowAPI,
    panelsApi,
    isStudio,
  });
  await componentGfppClickedEventListener({
    editorSDK,
    flowAPI,
    panelsApi,
  });
};

export const getIsStudio = (context: EditorReadyOptions) => {
  return context.origin?.subType === 'STUDIO';
};

export const getAppManifest: GetAppManifestFn = async (
  options,
  editorSDK,
  contextParams,
  flowAPI,
) => {
  return getAppManifestWrapper({ options, editorSDK, contextParams, flowAPI });
};

export const exports = (
  editorSDK: EditorSDK,
  context: EditorReadyOptions,
): AppExposedApis => {
  const biLogger = context.essentials.biLoggerFactory().logger();
  return {
    public: {
      uninstall: () =>
        editorSDK.application.uninstall('', { openConfirmation: true }), // TODO: https://jira.wixpress.com/browse/RST-6288
    },
    private: {
      reportMenuSettingsBi: () =>
        biLogger.report(
          bookingsAppSettingsOpened({
            referralInfo: 'next_available_widget',
            widget_name: 'next_available_widget',
          }),
        ),
    },
  };
};
