import { PLATFORM } from '../utils/constants';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const initPresetChangedEventListener = async ({
  editorSDK,
  widgetPresetsData,
  isStudio,
}: {
  editorSDK: FlowEditorSDK;
  widgetPresetsData: any;
  isStudio: boolean;
}) => {
  await editorSDK.addEventListener('presetChanged', async (event) => {
    const viewPort = await editorSDK.info.getCurrentViewport();
    // console.log('viewPort:', viewPort);

    // console.log('Event Listener:', event);
    // UPDATE WIDGE WIDTH. See https://dev.wix.com/docs/fed-guild/articles/blocks-bo-b/bo-b/editor-behavior-configuration/presets-in-bo-b#create-custom-logic-for-your-presets-panel
    const { presetId, componentRef } = event.detail;
    // console.log('presetId:', presetId);
    // console.log('componentRef:', componentRef);

    const isChangingMobilePreset = !!componentRef.variants;
    // console.log('isChangingMobilePreset', isChangingMobilePreset);

    const selectedPreset =
      widgetPresetsData.find((preset) => preset.id === presetId) ||
      widgetPresetsData[0];
    // console.log('selectedPreset:', selectedPreset);

    const isFullWidth = await editorSDK.components.isFullWidth('', {
      componentRef,
    });
    // console.log('isFullWidth:', isFullWidth);
    // Set widget width
    if (!isFullWidth) {
      if (isStudio) {
        const responsiveLayout: any =
          await editorSDK.document.responsiveLayout.get('token', {
            componentRef,
          });
        responsiveLayout.componentLayouts[0].width.value =
          isStudio && selectedPreset.layout.width === 320 ? 340 : 768;
        // console.log('Updating responsiveLayout:', responsiveLayout);
        editorSDK.document.responsiveLayout.update('token', {
          componentRef,
          responsiveLayout,
        });
      } else {
        editorSDK.document.components.layout.update('', {
          componentRef,
          layout: {
            width: selectedPreset.layout.width,
            height: selectedPreset.layout.height,
          },
        });
      }
    }

    if (viewPort.type !== PLATFORM.MOBILE && !isChangingMobilePreset) {
      // Need to import WIDGET_PRESET_IDS from constants
      // if (presetId != WIDGET_PRESET_IDS.SIDE_BY_SIDE) {
      //   console.log('Updating MOBILE preset to presetId:', presetId);
      //   await editorSDK.application.appStudioWidgets.changePreset('', {
      //     context: { viewport: PLATFORM.MOBILE },
      //     componentRef,
      //     stylePresetId: presetId,
      //     layoutPresetId: presetId,
      //   });
      // }

      // DOESNT WORK!
      // console.log('Updating TABLET preset to presetId:', presetId);
      await editorSDK.application.appStudioWidgets.changePreset('', {
        context: { viewport: PLATFORM.TABLET },
        componentRef,
        stylePresetId: presetId,
        layoutPresetId: presetId,
      });
    }

    // const currentLayout = await editorSDK.document.components.layout.get('', {componentRef: componentRef})
    // console.log('currentLayout:', currentLayout)
  });
};
