import { PLATFORM } from '../utils/constants';
import type { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { openShowHidePanel } from '../showHideActions';
import { reportGfppSettingsClicked } from '../biEvents';

export const widgetGfppClickedEventListener = async ({
  editorSDK,
  flowAPI,
  panelsApi,
  isStudio,
}: {
  editorSDK: any;
  flowAPI: PlatformControllerFlowAPI;
  panelsApi: any;
  isStudio: boolean;
}) => {
  await editorSDK.addEventListener('widgetGfppClicked', async (event) => {
    // console.log('Event Listener:', event);
    // console.log('isStudio:', isStudio);

    // const biLogger = context.essentials.biLoggerFactory().logger();
    const biLogger = flowAPI.essentials.biLoggerFactory().logger();
    const t = flowAPI.translations.t;
    // const biLogger = webBiLogger.factory().logger();
    const { id, componentRef } = event.detail;
    // const biReporter = new BiReporter(flowAPI.bi, componentRef.id);
    // await biReporter.init(editorSDK);

    const viewPort = await editorSDK.info.getCurrentViewport();
    console.log('viewPort:', viewPort);

    switch (id) {
      case 'elementsPanel':
        void openShowHidePanel(editorSDK, componentRef, flowAPI);
        break;
      case 'settingsPanel':
        void panelsApi.openBlocksPanel('Settings', componentRef, {
          overrides: {
            title: t('app.settings.headline'),
          },
        });
        // Send BI event 16:160
        // console.log('Firing event 16:160');
        reportGfppSettingsClicked(biLogger);
        break;
      case 'layoutPanel':
        // Do not use  componentRef.type, for Studio it always returns DESKTOP
        if (viewPort.type.includes(PLATFORM.MOBILE)) {
          void panelsApi.openBlocksPanel('Layout mobile', componentRef, {
            overrides: {
              title: t('app.layout.headline'),
            },
          });
        } else {
          if (isStudio) {
            void panelsApi.openBlocksPanel('Layout studio', componentRef, {
              overrides: {
                title: t('app.layout.headline'),
              },
            });
          } else {
            void panelsApi.openBlocksPanel('Layout', componentRef, {
              overrides: {
                title: t('app.layout.headline'),
              },
            });
          }
        }
        break;
      case 'manageAvailability':
        // console.log('Manage availability clicked');
        editorSDK.editor.openDashboardPanel('token', {
          url: '/bookings/services/templates-catalog',
        });
        break;
    }
  });
};
